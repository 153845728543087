/*
 * @Author: liz
 * @Date: 2023-01-19 16:02:34
 * @version: 
 * @Descripttion: 
 */
export default {
  namespaced: true,
  state: () => ({
    userInfo: {},
    isLogin: false,
  }),
  getters: {
    getUserInfo: (state) => {
      return state.userInfo;
    },
  },
  mutations: {
    setUserInfo(state, val) {
      state.userInfo = val;
    },
    setIsLogin(state, val) {
      state.isLogin = val;
    },
  },
};
