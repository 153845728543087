<!--
 * @Author: liz
 * @Date: 2023-03-12 15:43:07
 * @version: 
 * @Descripttion: 
-->
<template>
  <div class="footer-container">
    <div class="footer-txt" v-if="pageInfo.footCopyrightState == 1">
      {{ pageInfo.footCopyright }}
    </div>
    <!-- <div class="footer-txt">粤公网备案 3101150200XXXX号</div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Footer",
  computed: {
    ...mapGetters(["pageInfo"]),
  },
  data() {
    return {
      userInfo: {},
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.footer-container {
  width: 100%;
  min-width: 1200px;
  height: 70px;
  background-color: #424242;
  align-items: center;
  padding-left: 78px;
  box-sizing: border-box;
  color: #999;
  padding-top: 18px;

  .footer-txt {
    font-size: 12px;
    text-align: center;
    line-height: 16px;
  }
}
</style>
