<template>
	<div class="container">
		<Header />
		<MainHome />
		<Footer />
	</div>
</template>

<script>
import Header from '@/layout/Header.vue';
import Footer from '@/layout/Footer.vue';
import MainHome from '@/layout/MainHome.vue';

export default {
	data() {
		return {

		}
	},
	components: {
		Header,
		Footer,
		MainHome,
	},
	created() {

	},
	methods: {

	}
}
</script>
<style>

</style>
<style lang="less" scoped>
.container {
	width: 100%;
	min-height: calc(100vh - 144px);
}
</style>
