<!--
 * @Author: liz
 * @Date: 2023-03-05 20:07:38
 * @version: 
 * @Descripttion: 
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  beforeCreate() {
    const UA = window.navigator.userAgent.toLowerCase();
    
    const isAndroid = (UA && UA.indexOf('android') > 0);
    const isIOS = (UA && /iphone|ipad|ipod|ios/.test(UA));

    console.log(isAndroid,isIOS);
    console.log(window.location.href)
    if(isAndroid || isIOS) {
      console.log('动端环境')
      const url = window.location.href;
      if(url.search('https://pad.huiyishanji.com') == -1) {
        window.location.href =  'https://pad.huiyishanji.com';
      }
    }else {
      console.log('PC')
    }
  },
}
</script>

<style>
.strong {
  font-weight: 600;
}
* {
  margin: 0;
  padding: 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
*::-webkit-scrollbar {
  display: none;
}
#app {
  width: 100%;
  height: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
}
.el-message {
  top: 100px !important;
}
.confirmbtn {
  color: #ffffff;
  background-color: #13bfba !important;
  border-color: #13bfba !important;
}
.video-js .vjs-big-play-button {
  transform: scale(0.8);
}
</style>
