import { render, staticRenderFns } from "./LayoutClinic.vue?vue&type=template&id=0f1ed69f&scoped=true&"
import script from "./LayoutClinic.vue?vue&type=script&lang=js&"
export * from "./LayoutClinic.vue?vue&type=script&lang=js&"
import style1 from "./LayoutClinic.vue?vue&type=style&index=1&id=0f1ed69f&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f1ed69f",
  null
  
)

export default component.exports